let fcpDone = false,
  dclDone = false;

/**
 * Returns a promise that resolves once both both first-contentful-paint (FCP),
 * and 'DOMContentLoaded' has happened.
 */
export function onPageReady(): Promise<void> {
  return new Promise((resolve) => {
    let done = false;

    if (!window.PerformanceObserver) {
      // fallback for old browsers that don't support PerformanceObserver
      fcpDone = true;
    } else {
      const observer = new PerformanceObserver((entries) => {
        if (done) return;
        if (entries.getEntriesByName('first-contentful-paint').length > 0) {
          observer.disconnect();
          fcpDone = true;

          if (fcpDone && dclDone) {
            done = true;
            resolve();
          }
        }
      });

      observer.observe({ type: 'paint', buffered: true });
    }

    window.addEventListener('DOMContentLoaded', () => {
      if (done) return;
      dclDone = true;

      if (fcpDone && dclDone) {
        done = true;
        resolve();
      }
    });
  });
}
